import React from 'react'
import '../CSS/recentnewsview.css';
import {FiCalendar} from "react-icons/fi";
import {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {ApiWrt, BearerToken} from '../Tokens';

function RecentNewsView() {
    const [Data, setData] = useState([]);
    const ApiUrl = ApiWrt();
    const BToken = BearerToken();
    const loca = useLocation();
    // window.onnload = test();
    // test();
    function test() {


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + BToken);

        var formdata = new FormData();
        formdata.append("access_key", "5670");
        formdata.append("offset", "0");
        formdata.append("limit", "12");
        formdata.append("user_id", "1");
        formdata.append("language_id", localStorage.getItem('default_language_id'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${ApiUrl}/get_news`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }


    useEffect(() => {
        test()

    }, [])
    return (
        <div id='rnv-main'>
            <div id='rnv-content' className="">
                <h1 className="text-center"></h1>
                <div className="row">
                    <Link id='' to="/go"></Link>
                    {Data && Data.map((element) => (
                        <div className="col-md-4 " key={element.id}>
                            <Link id='Link-all' to={"/NewsView?Nid=" + element.id + "&Cid=" + element.category_id}>
                                <div id='rnv-card' className="card">
                                    <img id='rnv-card-image' src={element.image} className="card-img" alt="..."/>
                                    <div id='rnv-card-body' className="card-body">
                                        <button id='rnv-btnCatagory' className='btn btn-sm' type="button">{element.category_name}</button>
                                        <h5 id='rnv-card-title' className="card-title">{element.title.slice(0, 150)}...</h5>
                                        <p id="rnv-card-date"><FiCalendar size={18} id="rnv-logoCalendar"/>{element.date.slice(0, 10)}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RecentNewsView
