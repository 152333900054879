import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {ApiWrt, BearerToken} from '../Tokens';

import '../CSS/Terms&Privacy.css'
import {GiCheckedShield} from 'react-icons/gi';


function Privacy_Policy(props) {
    const handleClose = () => props.setPrivacy(false);
    const BToken = BearerToken();
    const ApiUrl = ApiWrt();


    const [Data, setData] = useState([]);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + BToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${ApiUrl}/get_pages?access_key=5670&language_id=14`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }, [])
    return (
        <div>
            {!Data || Data.length === 0
                ? ""
                :
                <Modal show={props.Privacy}
                       onHide={handleClose}
                       centered
                       id="modaltp">
                    <Modal.Header id='pp-modal-header'>
                        <GiCheckedShield style={{paddingRight: "10px"}} size={50}/>
                        {/* <img style={{ paddingRight: "10px", height: "2.5rem" }} src={Data[0].page_icon} alt="" /> */}
                        <Modal.Title>{Data[0].title}</Modal.Title>

                    </Modal.Header>
                    <Modal.Body id='pp-modal-body' dangerouslySetInnerHTML={{__html: Data[0].page_content}}></Modal.Body>
                </Modal>
            }
        </div>
    )
}

export default Privacy_Policy
