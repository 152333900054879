import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import {IoMdArrowForward} from 'react-icons/io';
import {ApiWrt, BearerToken} from '../Tokens';

function RecentNewsSection() {
    const ApiUrl = ApiWrt();

    const [Data, setData] = useState([]);
    const BToken = BearerToken();
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + BToken);

        var formdata = new FormData();
        formdata.append("access_key", "5670");
        formdata.append("offset", "0");
        formdata.append("limit", "8");
        formdata.append("user_id", "1");
        formdata.append("language_id", localStorage.getItem('default_language_id'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(`${ApiUrl}/get_news`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }, [])


    return (<>

            <div>
                <div id='rns-main'>
                    {!Data || Data.length === 0 ? null
                        :
                        <div id='rns-cards-main' className="">
                            <div id='rns-head-main' className=''>
                                <h4 id='rns-main-logo'>Recent News</h4>
                                <Link href='/' id='rns-Viewmore' to="/RecentNewsView">View More</Link>
                            </div>


                            <div id='rns-card-row'>
                                {
                                    Data.map((value, index) => {
                                        return (
                                            <div id='rns-card' className="card" key={index}>
                                                <img id='rns-image' src={value.image} className="card-img-top" alt="..."/>
                                                <div id='rns-img-overlay' className="card-img-overlay card-inverse">
                                                    <Link id='btnrnsCatagory' className='btn btn-sm' type="button" to={`/CategoryView?id=${value.category_id}`}>{value.category_name}</Link>
                                                </div>
                                                <div id='rns-card-body' className="card-block">
                                                    <p className="card-title">{value.title.slice(0, 80)}...</p>
                                                </div>
                                                <div id='rns-card-footer'>
                                                    <Link id='btnrnsRead' className='btn overlay' to={"/NewsView?Nid=" + value.id + "&Cid=" + value.category_id} type="button">Read More<IoMdArrowForward id='rns-arrow' size={25}/></Link>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }


                    <div id='rns-main-mobile' className="">
                        <div id='rns-head-main' className=''>
                            <h5 id='rns-main-logo'>Recent News</h5>
                            <a href='/' id='rns-Viewmore'>View More</a>
                        </div>

                        <div id='rns-card-row'>
                            {!Data ? "LOADING.." : Data.map((element, index) => (
                                <Link id='Link-all' to={"/NewsView?Nid=" + element.id + "&Cid=" + element.category_id} key={index}>
                                    <div id='rns-card' className="card">
                                        <img id='rns-image' src={element.image} className="card-img-top" alt="..."/>
                                        {/* <div id='rns-img-overlay' className="card-img-overlay card-inverse">
                                </div> */}
                                        <div id='rns-card-body' className="card-block">
                                            <Link id='btnrnsCatagory' className='btn btn-sm' type="button" to={`/CategoryView?id=${element.category_id}`}>{element.category_name}</Link>
                                            <p className="card-title">{element.title.slice(0, 80)}...</p>
                                        </div>
                                        {/* <div id='rns-card-footer'>
                                </div> */}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentNewsSection
