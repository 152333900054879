import './App.css';
// import Header from './Componenets/Header';
import HomePage from './Componenets/HomePage';
import Footer from './Componenets/Footer';
import {Route, Routes} from 'react-router';
import AboutUs from './Componenets/AboutUs';
import Notification from './Componenets/Notification';
import ContactUs from './Componenets/Contact_Us';
import NewsNotification from './Componenets/NewsNotification';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsView from './Componenets/NewsView';
import Categories from './Componenets/Categories';
import CategoryView from './Componenets/CategoryView';
import CatNav from './Componenets/CatNav';
import Newsbar from './Componenets/Newsbar';

// import Spinner from './Componenets/Spinner';
// import {useEffect, useState} from 'react';

import BookmarkSection from './Componenets/BookmarkSection';
import TagNewsview from './Componenets/TagNewsview';
import LiveNews from './Componenets/LiveNews';
import BreakingNewsView from './Componenets/BreakingNewsView';
import RecentNewsView from './Componenets/RecentNewsView';
import VideoNewsview from './Componenets/VideoNewsview';
import SearchNewsView from './Componenets/SearchNewsView';
import Breaking_NewsView from './Componenets/Breaking_NewsView';
import {useEffect, useState} from "react";
import {ApiWrt, BearerToken} from "./Tokens";


function App() {
    const [hasToken, setHasToken] = useState(false);

    const ApiUrl = ApiWrt();
    const BToken = BearerToken();
    useEffect(() => {
        let storage_token = localStorage.getItem('token');
        if (!storage_token) {
            fetch(`${ApiUrl}/generate_token`, {method: 'GET'})
                .then(response => response.text())
                .then(result => {
                    let token = result.replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "")
                    token = token.replace(/(<style[\w\W]+style>)/g, "").trim();
                    localStorage.setItem('token', token);
                    localStorage.setItem('lastTokenFetched', Date.now());

                    // Fetch languages
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", "Bearer " + token);

                    var formdata = new FormData();
                    formdata.append("access_key", "5670");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                    };

                    fetch(`${ApiUrl}/get_languages_list`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            setHasToken(true);
                            const selectedLanguage = localStorage.getItem('default_language_id');
                            if (!selectedLanguage) {
                                const lang = result.data.filter((language) => {
                                    return language.id == result.default_language
                                });
                                localStorage.setItem('default_language', lang[0].language);
                                localStorage.setItem('default_language_id', lang[0].id);
                            }
                        })
                        .catch(error => console.log('error', error));
                })
                .catch(error => console.log('error', error));
        } else {
            setHasToken(true);
        }
    }, [])
    return (
        <>
            {/* {isloading ? <Spinner /> : (<> */}
            {/*<Header/>*/}
            {
                hasToken ?
                    <>
                        <Newsbar/>
                        <CatNav/>
                        <Routes>
                            <Route exact path='/' element={<HomePage/>}></Route>
                            <Route exact path='/TagNewsview' element={<TagNewsview/>}></Route>
                            <Route exact path='/Breaking_NewsView' element={<Breaking_NewsView/>}></Route>
                            <Route exact path='/VideoNewsview' element={<VideoNewsview/>}></Route>
                            <Route exact path='/SearchNewsView' element={<SearchNewsView/>}></Route>
                            <Route exact path='/RecentNewsView' element={<RecentNewsView/>}></Route>
                            <Route exact path='/Bookmark' element={<BookmarkSection/>}></Route>
                            <Route exact path='/NewsView' element={<NewsView/>}></Route>
                            <Route exact path='/CategoryView' element={<CategoryView/>}></Route>
                            <Route exact path='/Categories' element={<Categories/>}></Route>
                            <Route exact path='/about_us' element={<AboutUs/>}></Route>
                            <Route exact path='/LiveNews' element={<LiveNews/>}></Route>
                            <Route exact path='/BreakingNews' element={<BreakingNewsView/>}></Route>
                            <Route exact path='/notification' element={<Notification/>}></Route>
                            <Route exact path='/Contact_us' element={<ContactUs/>}></Route>
                            <Route exact path='/News_Notification' element={<NewsNotification/>}></Route>
                            <Route exact path='/Persnol_Notification' element={<Notification/>}></Route>
                        </Routes>

                        <Footer/>
                    </>
                    : "LOADING"
            }
        </>
    );
}

export default App;
