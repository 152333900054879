import React from 'react'
import '../CSS/BreakingNewsView.css';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import {ApiWrt, BearerToken} from '../Tokens';
import BreadcrumbNav from './BreadcrumbNav'
import no_image from "../images/no_image.jpeg";

function BreakingNewsView() {
    const [Data, setData] = useState([]);
    const BToken = BearerToken();
    const ApiUrl = ApiWrt();

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer" + BToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${ApiUrl}/get_breaking_news?access_key=5670&language_id=${localStorage.getItem('default_language_id')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }, [])
    return (
        <>
            <BreadcrumbNav SecondElement="Breaking News" ThirdElement="0"/>
            <div id='BNV-main'>
                <div id='BNV-content' className="">
                    <h1 className="text-center"></h1>
                    <div className="row">
                        <Link id='' to="/go"></Link>
                        {Data && Data.map((element) => (
                            <div className="col-md-4 " key={element.id}>
                                <Link id='Link-all' to={"/Breaking_NewsView?BNid=" + element.id}>
                                    <div id='BNV-card' className="card">
                                        <img id='BNV-card-image' src={element.image ? element.image : no_image} className="card-img" alt="..."/>
                                        <div id='BNV-card-body' className="card-body">
                                            {/* <button id='BNV-btnCatagory' className='btn btn-sm' type="button" >{element.category_name}</button> */}
                                            <h5 id='BNV-card-title' className="card-title">{element.title.slice(0, 150)}...</h5>
                                            {/* <p id="BNV-card-date"><FiCalendar size={18} id="BNV-logoCalendar" />{element.date.slice(0, 10)}</p> */}
                                            {/* <Link id='btnBNVRead' className='btn overlay' type="button" to="/NewsView" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BreakingNewsView

