import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAW0uNcwtwSDzTjkKwsn3EZtjJgunBNPpE",
    authDomain: "news-c6cd4.firebaseapp.com",
    projectId: "news-c6cd4",
    storageBucket: "news-c6cd4.appspot.com",
    messagingSenderId: "948837222778",
    appId: "1:948837222778:web:c43aff5a12b1687983eec3",
    measurementId: "G-JQGYLEF8J4"
  };
  


const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};
