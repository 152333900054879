import {ApiWrt} from "./index";
import moment from "moment/moment";

const BearerToken = () => {
    const ApiUrl = ApiWrt();
    let lastFetched = localStorage.getItem('lastTokenFetched');
    const diffInMinutes = moment().diff(moment(parseInt(lastFetched)), 'minutes');

    if (!diffInMinutes || diffInMinutes > 60) {
        fetch(`${ApiUrl}/generate_token`, {method: 'GET'})
            .then(response => response.text())
            .then(result => {
                let token = result.replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "")
                token = token.replace(/(<style[\w\W]+style>)/g, "").trim();
                localStorage.setItem('token', token);
                localStorage.setItem('lastTokenFetched', Date.now());
            })
            .catch(error => console.log('error', error));
    }
    return localStorage.getItem('token');
};

export default BearerToken