import React, {useEffect, useState} from "react";
import {BsFillPlayFill} from "react-icons/bs";
import Card from "react-bootstrap/Card";
import {FiCalendar} from "react-icons/fi";
import {Link} from "react-router-dom";
import VideoPlayerModal from "./VideoPlayerModal";
import {ApiWrt, BearerToken} from '../Tokens';


function VideoPlayerSection() {
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const BToken = BearerToken();
    const ApiUrl = ApiWrt();

    useEffect(() => {
        var myHeaders = new Headers();

        myHeaders.append(
            "Authorization",
            "Bearer " + BToken
        );

        var formdata = new FormData();
        formdata.append("access_key", "5670");
        formdata.append("language_id", localStorage.getItem('default_language_id'));

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };


        fetch(`${ApiUrl}/get_videos`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setData(result.data);
            })
            .catch((error) => console.log("error", error));
    }, []);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    return (

        !Data || Data.length === 0 ? (
            null
        ) : (
            <div id="vps-main">
                <div id="vps-head-main" className="">
                    <p id="vps-main-logo">Video News To Explore</p>
                    <Link href="/" id="vps-Viewmore" to={'/VideoNewsview'}>
                        View More
                    </Link>
                </div>

                <div id="vps-body">
                    <div id="vps-body-left">
                        {
                            Data[0] ?
                                <Card id="vps-main-card" className="text-black">
                                    <Card.Img
                                        id="vps-main-image"
                                        src={Data[0].image}
                                        alt="Card image"
                                    />

                                    <Card.ImgOverlay>
                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[0].content_value)}>
                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60}/>
                                        </Link>
                                    </Card.ImgOverlay>
                                    <Card.Body id="vps-card-body">
                                        <Card.Title id="vps-main-title">{Data[0].title}</Card.Title>
                                        <Card.Text id="vps-main-date">
                                            <FiCalendar size={18} id="logoCalendar"/>
                                            {Data[0].date.slice(0, 10)}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                : null
                        }

                    </div>

                    <div id="vps-body-right">
                        {
                            Data[1] ?
                                <Card id="vps-image-cards" className="text-black">
                                    <Card.Img
                                        id="vps-secondry-images"
                                        src={Data[1].image}
                                        alt="Card image"
                                    />
                                    <Card.ImgOverlay>

                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[1].content_value)}>
                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60}/>
                                        </Link>
                                        <p id="vps-card-title">
                                            <b>{Data[1].title}</b>
                                        </p>
                                    </Card.ImgOverlay>
                                </Card>
                                : null
                        }

                        {
                            Data[2] ? <Card id="vps-image-cards" className="text-black">
                                <Card.Img
                                    id="vps-secondry-images"
                                    src={Data[2].image}
                                    alt="Card image"
                                />
                                <Card.ImgOverlay>
                                    <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[2].content_value)}>
                                        <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60}/>
                                    </Link>
                                    <p id="vps-card-title">
                                        <b>{Data[2].title}</b>
                                    </p>
                                </Card.ImgOverlay>
                            </Card> : null
                        }

                    </div>

                    <VideoPlayerModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        // backdrop="static"
                        keyboard={false}
                        url={Video_url}
                        // title={Data[0].title}
                    />

                </div>

            </div>
        )

    );
}

export default VideoPlayerSection;
