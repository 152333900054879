import React, {useState} from 'react'
import '../CSS/categories.css';
import {Link} from 'react-router-dom';
import {IoArrowForwardCircleSharp} from 'react-icons/io5';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useEffect} from 'react';
import {ApiWrt, BearerToken} from "../Tokens";

function Categories(props) {
    const [show, setShow] = useState(false);
    const [Data, setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const BToken = BearerToken();
    const ApiUrl = ApiWrt();
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + BToken);

        var formdata = new FormData();
        formdata.append("access_key", "5670");
        formdata.append("offset", "0");
        formdata.append("limit", "16");
        formdata.append("language_id", localStorage.getItem('default_language_id'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${ApiUrl}/get_category`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }, [])

    return (
        <>

            <Link variant="primary" className='nav-link active me-4' onClick={handleShow}>
                CATEGORIES
            </Link>

            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>CATEGORIES</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {Data && Data.map((element) => (
                        <div id='cat-section-card' key={element.id} className="card">
                            <img id='cat-section-card-image' src={element.image} className="card-img" alt="..."/>
                            <div id='cat-section-card-body' className="card-img-overlay">
                                <h3 id='cat-card-text' className="card-text">{element.category_name}</h3>
                                <button id='btn-cat-more' className='btn' type="button"><IoArrowForwardCircleSharp size={40}/></button>
                            </div>
                        </div>
                    ))}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}


export default Categories
